<template>
    <div class="line" :style="lineStyle"></div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        propValue: {
            type: String,
            default: "",
        },
        element: {
            type: Object,
            default: () => {},
        }
    },
    computed: {
        lineStyle: function() {
            const {
                backgroundColor
            } = this.element.style || {};
            return {backgroundColor: backgroundColor};
        }
    }
}
</script>
<style lang="scss" scoped>
.line {
    width: 100%;
    height: 100%;
}
</style>